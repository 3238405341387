import React from "react";
import "../index.css";
import msg from "../images/message.png";
import person from "../images/person.png";
import DotGrid from "../images/DotGrid.png";
import Button from "../images/Button.png";
import Black from "../images/Black.png";
import group from "../images/Group.png";
import Rectangle from "../images/Rectangle.svg";
import Tyre from "../images/Tyre.png";
import Pre_qualification from "../images/Pre-qualification.png";
import bottomLeft from "../images/bottomLeft.png";
import BottomMid from "../images/BottomMid.png";
import footer from "../images/footer.png";
import linkedin from "../images/linkedin.png";
import email from "../images/email.png";
import Access_E_Biz from "../images/Access-E-Biz.png";
import doller from "../images/doller.png";
import hand from "../images/hand.png";
import stairs from "../images/stairs.png";
import peoples from "../images/Peoples.png";
import black_circle from "../images/black-circle.png";
import headerBgImg from "../images/headerBgImg.png";
import { Navigate } from "react-router-dom";

const Home = () => {
  const onRefresh = () => {
    window.location.reload(false);
  }
  return (
    <div className="w-full overflow-hidden ">
      {/* header Sec */}
      <div className=" h-[200px] bg-no-repeat bg-BgImg  w-full flex md:bg-cover xl:bg-cover sm:bg-cover sm:h-[300px] bg-cover md:h-[350px] xl:h-[760px] ">
        <div className="xl:mx-48 w-full sm:mx-24 mx-12 ">
          <div className="flex justify-between ">
            <div className="  items-center top-0 md:left-14  xl:left-52 left-12 sm:left-[104px]  bg-Primary rounded-br-[15px] md:rounded-br-[30px] xl:w-[240px] md:w-[150px] sm:w-[120px] sm:h-[55px] w-[60px] h-7 xl:h-[110px] md:h-20 p-1 md:p-5   xl:p-4 ">
              <h1 className="text-center xl:text-[40pt] md:text-[20pt] sm:text-[20pt] text-[11pt] text-white font-extrabold custom-fonts ">
                E-Biz
              </h1>
            </div>

            <div className=" xl:top-10  md:top-5 sm:top-5 top-4 right-4 sm:right-[62px] xl:right-32 md:right-28 flex items-center justify-between ">
              <div className="flex items-center sm:mr-10 xl:mr-20 md:mr-10 mr-7 ">
                <img src={msg} className="xl:w-5 md:w-3 w-3 " />
                <a className=" text-white xl:text-[15px] md:text-[12px] sm:text-[8px] text-[4px] font-medium xl:ml-2 custom-fonts" href="mailto:Info@mpowerebiz.co.za?subject=${encodeURIComponent('') || '' ">
                  Info@mpowerebiz.co.za
                </a>
              </div>

              <a className="flex items-center" href="https://application.mpowerebiz.co.za/" target={'_blank'}>
                <img
                  src={Black}
                  className="xl:mt-5 md:mt-3 mt-2 sm:w-[80px] xl:w-[179px] md:w-[80px] w-[40px] "
                />
                <img
                  src={Button}
                  className="absolute xl:ml-4 md:ml-2 ml-1 w-[40px]  sm:w-[80px] xl:w-[179px] md:w-[80px]  "
                />
              </a>
            </div>
          </div>

          <div className="xl:mt-[100px] md:mt-[40px] sm:mt-[45px] mt-3">
            <h3 className=" text-white xl:text-[20px] md:text-[13px]  sm:text-[12px] text-[9px] sm:ml-1 font-medium xl:ml-2 md:ml-1 custom-fonts">
              Welcome to E-Biz, the premier sales platform<br></br>for
              professionals in the tyre business
            </h3>
            <h3 className=" text-white xl:mt-8 md:mt-2 mt-1 sm:mt-3 sm:text-[15pt] xl:text-[35pt] md:text-[17pt] text-[12px] font-bold md:ml-1 custom-fonts">
              Be Your Own Boss<br></br> Join the Revolution<br></br> in the Tyre
              Biz
            </h3>

            <a className="xl:w-[170px] md:w-[100px] sm:w-[80px] w-fit p-[3px] text-[8px] sm:text-[10px] xl:text-[16px] md:text-[14px] shadow-md md:ml-2 xl:ml-3 sm:ml-1 sm:mt-2 mt-2 xl:mt-10 xl:h-8 xl:p-5 md:p-1 flex justify-center items-center text-white custom-fonts font-medium  bg-Primary "
              href="https://application.mpowerebiz.co.za/" target={'_blank'}>
              Join E-biz
            </a>
          </div>

          <div className=" absolute xl:right-48  md:right-24 right-20 sm:right-28 top-[125px] xl:top-[400px] md:top-[180px] sm:top-[130px] ">
            <img
              src={DotGrid}
              className="absolute xl:right-10 md:right-5 right-2 sm:right-5 top-3 w-[130px] sm:w-[220px] sm:h-[220px] xl:w-[450px] xl:h-[450px] md:w-[220px] md:h-[220px] "
            />
            <img
              src={person}
              className="xl:w-[430px] md:w-[200px] w-[130px] sm:w-[200px] sm:h-[200px] md:h-[200px] xl:h-[440px] mt-5 sm:mt-8 xl:mt-16 bg-contain xl:rounded-[50px] rounded-[10px] "
            />
          </div>

          <div className="xl:mt-[190px] flex sm:ml-7 xl:ml-9 md:ml-12 sm:mt-12 mt-[134px] ml-12 ">
            <img
              src={group}
              className="xl:w-[440px] sm:w-[220px]  xl:h-[170px] w-[150px] "
            />
          </div>
        </div>
      </div>

      {/* mid-Top section */}
      <div className="xl:mt-[260px] absolute  mt-[145px] sm:mt-[130px]  flex flex-col justify-center xl:mx-0 md:mx-0 xl:ml-3 mx-12 items-center">
        <h1 className="text-center xl:text-[40pt] text-[17pt] sm:text-[24pt] font-extrabold custom-fonts">
          Take Control of Your<br></br> Career with E-Biz
        </h1>
        <p className="xl:mt-5 mt-3 xl:text-center sm:text-center text-justify custom-fonts md:tracking-wider xl:tracking-wider md:px-[70px] xl:px-[320px]  text-black font-[400] text-[11px] sm:text-[11px] md:text-[12px] xl:text-[16px] ">
          <h3 className="font-semibold custom-fonts text-black text-[12px] sm:text-[11px] xl:text-[16px] md:text-[12px]">
            Are you tired of working the same routine and ready to be your own
            boss?
          </h3>
          <br></br>
          E-Biz is the perfect opportunity for you to take control of your
          career, earn what you deserve and enjoy work life balance. We are
          looking for qualified people who understand the tyre industry and have
          a proven track record for success. With E-Biz, you will have access to
          a wide range of products and services, and the support and resources
          you need to run a thriving business.
        </p>

        <h1 className="xl:pt-7 md:pt-3 mt-2 custom-fonts text-black xl:text-[16px] sm:text-[11px] text-left text-[12px] flex sm:flex-row flex-col font-semibold ">
          No Start Up Capital Required
          <div className="flex justify-center xl:mx-2 mx-1 sm:mt-0 mt-3 items-center">
            <img
              src={black_circle}
              className="xl:w-3 md:w-2 w-[8px] md:mr-2 mr-1 "
            />
        No Fixed Costs 
          </div>
          <div className="flex justify-center xl:mx-2 mx-1 sm:mt-0 mt-3 items-center">
            <img
              src={black_circle}
              className="xl:w-3 md:w-2 w-[8px] md:mr-2 mr-1 "
            />
            No Monthly Fees
          </div>
        </h1>

        <a className="xl:w-[170px] sm:w-[80px] md:w-[100px] w-fit p-[3px] text-[12px] xl:text-[16px] sm:text-[11px] px-1 md:text-[14px] mt-3 shadow-md xl:mt-16  xl:h-8 xl:p-5 md:px-2 flex justify-center items-center text-white custom-fonts font-medium md:mr-12 bg-Primary "
          href="https://application.mpowerebiz.co.za/" target={'_blank'}>
          Join E-biz
        </a>
      </div>

      {/* Mid Section */}
      <div className="sm:w-full bg-cover bg-center w-full  flex justify-center items-center bg-arrow xl:w-full xl:h-[1300px] sm:h-[800px] h-[2200px]  sm:bg-no-repeat sm:bg-cover mt-[330px] xl:mt-[700px] ">
        {/* <img
          className="flex justify-center items-center sm:w-full sm:h-full  "
          src={Rectangle}
        /> */}

        <div className="items-center absolute xl:mt-[230px] md:mt-[40px] sm:mt[30px] mt-[200px] flex flex-col ">
          <h1 className="font-bold custom-fonts xl:text-[58px] sm:text-[34px] sm:mt-4 xl:mt-6 text-[16pt] ">
            So how does it work?
          </h1>
          <h2 className="font-semibold custom-fonts xl:text-[40px] md:text-[18px]  sm:text-[12px] text-[8pt]  ">
            Here's a step-by-step guide on how E-Biz works:
          </h2>

          <div className="flex justify-center xl:mt-0 mt-1 items-center">
            <div className=" sm:absolute flex flex-col justify-center items-center ">
              <div className="sm:flex  md:my-2 sm:my-1 items-center">
                <div className="xl:w-[400px] md:w-[250px] md:h-[120px] sm:w-[200px] sm:h-[90px] w-[260px] h-[280px] py-2 my-3 xl:h-[180px] flex  flex-col xl:md:flex-row  md:flex-row  sm:flex-row items-center justify-center m-1 md:mx-3 xl:mx-4 bg-slate-200 rounded-[10px] opacity-90  xl:rounded-[30px]">
                  {/* image */}
                  <img
                    src={Pre_qualification}
                    className="w-[45px]  sm:w-[38px] md:w-[50px] xl:w-[104px]  "
                  />
                  {/* text */}
                  <div className="md:ml-1 mx-2 sm:mx-0 text-left ">
                    <h3 className="xl:text-[12px] text-[9px] sm:text-[7px] custom-fonts font-[400] ">
                      Step1
                    </h3>
                    <p className=" font-normal xl:text-[12px] md:text-[8px] sm:text-[6px] text-[12px] custom-fonts ">
                      <h1 className="font-semibold custom-fonts text-[18px] sm:text-[12px] md:text-[18px]">
                        Pre-qualification
                      </h1>
                      To ensure that our platform is filled with
                      qualified and skilled professionals, we have a
                      pre-qualification process. This process includes an
                
                      assessment of your application and interviews to
                      validate your qualifications and experience in
                      the tyre industry.
                    </p>
                  </div>
                </div>
                <div className="xl:w-[400px] md:w-[250px] md:h-[120px] sm:w-[200px] sm:h-[90px] w-[260px] h-[280px] py-2 my-3 xl:h-[180px] flex  flex-col xl:md:flex-row  md:flex-row  sm:flex-row items-center justify-center m-1 md:mx-3 xl:mx-4 bg-slate-200 rounded-[10px] opacity-90  xl:rounded-[30px]">
                  {/* image */}
                  <img
                    src={Access_E_Biz}
                    className="w-[55px]  sm:w-[38px] md:w-[50px] xl:w-[104px] "
                  />

                  {/* text */}
                  <div className="md:ml-1 mx-2 sm:mx-0 text-left ">
                    <h3 className="xl:text-[12px] text-[9px] sm:text-[7px] custom-fonts font-[400] ">
                      Step2
                    </h3>
                    <p className=" font-normal xl:text-[12px] md:text-[8px] sm:text-[6px] text-[12px] custom-fonts ">
                      <h1 className="font-semibold custom-fonts text-[18px] sm:text-[12px] md:text-[18px]">
                        Access to E-Biz
                      </h1>
                      Should you qualify, you will be given access to
                      the full E-Biz package. This includes access to all
                       the tools and resources you need to run your
                       business, including sales opportunities, goals,
                      and tasks.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sm:flex  md:my-2 sm:my-1 items-center">
                <div className="xl:w-[400px] md:w-[250px] md:h-[120px] sm:w-[200px] sm:h-[90px] w-[260px] h-[280px] py-2 my-3 xl:h-[180px] flex  flex-col xl:md:flex-row  md:flex-row  sm:flex-row items-center justify-center m-1 md:mx-3 xl:mx-4 bg-slate-200 rounded-[10px] opacity-90  xl:rounded-[30px]">
                  {/* image */}
                  <img
                    src={hand}
                    className="w-[55px]  sm:w-[38px] md:w-[50px] xl:w-[104px] "

                  />
                  {/* text */}
                  <div className="md:ml-1 mx-2 sm:mx-0 text-left ">

                    <h3 className="xl:text-[12px] text-[9px] sm:text-[7px] custom-fonts font-[400] ">
                      Step3
                    </h3>
                    <p className=" font-normal xl:text-[12px] md:text-[7px] sm:text-[6px] text-[12px] custom-fonts ">
                      <h1 className="font-semibold custom-fonts text-[18px] sm:text-[12px]  md:text-[15px]  xl:text-[18px]">
                        Exclusive access to your region / customer
                        channel
                      </h1>
                      You will have the opportunity to be the exclusive
                       
                      provider in your own region or customer channel,  
                      ensuring that you have a steady stream of customers
                        and sales.
                    </p>
                  </div>
                </div>
                <div className="xl:w-[400px] md:w-[250px] md:h-[120px] sm:w-[200px] sm:h-[90px] w-[260px] h-[280px] py-2 my-3 xl:h-[180px] flex  flex-col xl:md:flex-row  md:flex-row  sm:flex-row items-center justify-center m-1 md:mx-3 xl:mx-4 bg-slate-200 rounded-[10px] opacity-90  xl:rounded-[30px]">
                  {/* image */}
                  <img
                    src={doller}
                    className="w-[50px]  sm:w-[38px] md:w-[50px] xl:w-[104px] "

                  />
                  {/* text */}
                  <div className="md:ml-1 mx-2 sm:mx-0 text-left ">
                    <h3 className="xl:text-[12px] text-[9px] sm:text-[7px] custom-fonts font-[400] ">
                      Step4
                    </h3>
                    <p className=" font-normal xl:text-[12px] md:text-[7px] sm:text-[6px] text-[12px] custom-fonts ">
                      <h1 className="font-semibold custom-fonts text-[18px] sm:text-[12px]  md:text-[18px]  xl:text-[18px]">
                        Utilise the subsidy
                      </h1>
                      As a member of E-Biz, you will be given an 
                      expense subsidy for operating expenses each month
                      to help with costs associated with running your
                      business.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sm:flex  md:my-2 sm:my-1 items-center">
                <div className="xl:w-[400px] md:w-[250px] md:h-[120px] sm:w-[200px] sm:h-[90px] w-[260px] h-[280px] py-2 my-3 xl:h-[180px] flex  flex-col xl:md:flex-row  md:flex-row  sm:flex-row items-center justify-center m-1 md:mx-3 xl:mx-4 bg-slate-200 rounded-[10px] opacity-90  xl:rounded-[30px]">
                  {/* image */}
                  <img
                    src={stairs}
                    className="w-[50px]  sm:w-[38px] md:w-[50px] xl:w-[104px] xl:mt-5 "

                  />
                  {/* text */}
                  <div className="md:ml-1 mx-2 sm:mx-0 text-left ">
                    <h3 className="xl:text-[12px] text-[9px] sm:text-[7px] custom-fonts font-[400] ">
                      Step5
                    </h3>
                    <p className=" font-normal xl:text-[12px] md:text-[7px] sm:text-[6px] text-[12px] custom-fonts ">
                      <h1 className="font-semibold custom-fonts text-[18px] sm:text-[12px]  md:text-[15px]  xl:text-[18px]">
                        Access a wide range of products and services
                      </h1>
                      Our platform offers a wide range of wheel and tyre
                        products, ensuring that you have everything you
                        need to succeed.
                    </p>
                  </div>
                </div>
                <div className="xl:w-[400px] md:w-[250px] md:h-[120px] sm:w-[200px] sm:h-[90px] w-[260px] h-[280px] py-2 my-3 xl:h-[180px] flex  flex-col xl:md:flex-row  md:flex-row  sm:flex-row items-center justify-center m-1 md:mx-3 xl:mx-4 bg-slate-200 rounded-[10px] opacity-90  xl:rounded-[30px]">
                  {/* image */}
                  <img
                    src={peoples}
                    className="w-[55px]  sm:w-[38px] md:w-[50px] xl:w-[104px] xl:mt-5 "
                  />
                  {/* text */}
                  <div className="md:ml-1 mx-2 sm:mx-0 text-left ">
                    <h3 className="xl:text-[12px] text-[9px] sm:text-[7px] custom-fonts font-[400] ">
                      Step6
                    </h3>
                    <p className=" font-normal xl:text-[12px] md:text-[7px] sm:text-[6px] text-[12px] custom-fonts ">
                      <h1 className="font-semibold custom-fonts text-[18px] sm:text-[12px]  md:text-[15px]  xl:text-[18px]">
                        Receive marketing and sales support
                      </h1>
                      We provide marketing and sales support to ensure
                      sustainable growth in your target market.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <a className="absolute sm:w-[80px] xl:w-[170px] md:w-[100px] w-fit p-[3px] text-[12px] md:text-[14px] xl:text-[16px] sm:text-[11px] sm:mt-[450px] xl:mt-[950px] md:mt-[550px] mt-[1830px] shadow-md  md:h-8 xl:h-8 xl:p-5 md:px-2 flex justify-center items-center text-white custom-fonts font-medium  bg-Primary "
              href="https://application.mpowerebiz.co.za/" target={'_blank'}>
              Join E-biz
            </a>
            <img
              src={Tyre}
              className="xl:h-[800px] md:h-[500px] sm:h-[440px] h-[200px] hidden sm:flex "
            />
          </div>
        </div>

      </div>

      <div className="md:mt-10  sm:mt-6   flex flex-col justify-center md:mx-0 mx-10 sm:mx-4 items-center">
        <h1 className="text-center xl:text-[35pt] md:text-[20pt] sm:text-[15pt] text-[14pt] font-extrabold custom-fonts">
          Unlock High Earning Potential<br></br> with E-Biz's Exclusive Platform
        </h1>
        <p className="xl:mt-5 mt-3 sm:mt-4 md:text-center sm:text-center text-justify custom-fonts md:tracking-wider xl:px-[300px] md:px-[100px] px-[10px] text-black font-[400] text-[11px] sm:text-[11px] md:text-[14px] ">
          As a member of our platform, you will enjoy the freedom and
          flexibility of being self-employed, while also having access to a
          large customer base, a comprehensive range of wheel and tyre products,
          be an exclusive provider in your own region or sales channel and the
          opportunity to earn a high income.
        </p>

        <img src={BottomMid} className="w-fit h-fit xl:mt-6 mt-4 " />
        <p className=" md:text-center sm:text-center text-justify custom-fonts md:tracking-wider sm:text-[11px] xl:px-[300px] md:px-[100px] px-[10px] text-black font-[400] text-[11px]  md:text-[16px] ">
          If you are ready to take your career to the next level, join E-Biz
          today and start building your own successful business. So why wait?
          Apply for E-Biz today and take the first step towards reaching your
          financial and professional goals in the tyre industry
        </p>

        <a className=" xl:w-[170px] md:w-[100px] sm:w-[80px] w-fit p-[3px] sm:text-[11px] text-[7px] xl:text-[16px] md:mt-14 mt-3 md:ml-3 shadow-md md:h-4 xl:h-8 md:p-5 flex justify-center items-center text-white custom-fonts font-medium bg-Primary "
          href="https://application.mpowerebiz.co.za/" target={'_blank'}>
          Join E-biz
        </a>
      </div>

      {/* footer section */}
      <div className="w-full h-full ">
        <div className=" absolute w-full justify-items-center items-center grid grid-cols-3 md:gap-0 mt-[40px] md:px-20 sm:px-10 sm:mt-[90px]  md:mt-[110px] xl:mt-[240px] ">
          {/* left */}
          <button onClick={() => onRefresh()}>
            <img
              src={bottomLeft}
              className=" w-[60px] xl:w-[250px] sm:w-[120px] items-center  "
            />
          </button>

          {/* mid */}
          <a className="md:ml-9 ml-3 sm:ml-4 md:mt-0 mt-2" href=" https://www.linkedin.com/in/mpower-ebiz-328501267" target={'_blank'} >
            <img
              src={linkedin}
              className="xl:w-12 sm:w-[30px] h-fit w-[25px] "
            />
          </a>

          <div className=" flex flex-col sm:mt-2 justify-start  ">
            <h3 className="text-white custom-fonts xl:text-[13px] sm:text-[12px] text-[7px]">
              For enquiries:
            </h3>
            <div className="flex justify-center items-center  ">
              <img src={email} className="xl:w-4 sm:w-3 w-2 h-fit" />
              <a className="text-white custom-fonts xl:text-[12px] sm:text-[10px] text-[6px] md:ml-2 ml-1"
                href="mailto:Info@mpowerebiz.co.za?subject=${encodeURIComponent('') || '' "
              >
                Info@mpowerebiz.co.za
              </a>
            </div>
          </div>
        </div>

        {/* terms & condition's */}
        <div className="absolute xl:mt-[360px] md:mt-[210px] sm:mt-[169px] justify-center items-center mt-[90px] w-full ">
          <h3 className="text-white custom-fonts xl:text-[12px] text-[5px] sm:text-[9px]  text-center">
            © Mpower Ebiz 2023 ALL Rights Reserved: Terms Of Use | Privacy
            Statement
          </h3>
        </div>

        <img src={footer} className="w-full h-full" />
      </div>
    </div>
  );
};

export default Home;
